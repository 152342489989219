<template>
  <n-space vertical>


    <b>Konkurso dalyviai ir pasiūlymai</b>

    <n-dynamic-input
      v-model:value="modelValues"
      placeholder="Please type here"
      :on-create="onCreate"
      #="{ value }"
    >
      <n-input v-model:value="value.name" type="text" placeholder="Įmonės pavadinimas"/>
      <n-input-number v-model:value="value.price" :show-button="false" placeholder="Kaina">
        <template #prefix>€</template>
      </n-input-number>
      <n-input-number v-model:value="value.quality" :show-button="false" placeholder="Kokybė">
        <template #prefix>#</template>
      </n-input-number>
    </n-dynamic-input>

    <br>

    <b>Kainos ir kokybės koeficientai</b>

    <n-slider v-model:value="coef" :step="10" />
    <n-input-number size="small" v-model:value="coef">
      <template #prefix>koef.:</template>
    </n-input-number>

    <n-space vertical>
      <div>Kokybės koeficientas: {{ coef }}</div>
      <div>Kainos koeficientas:  {{ 100 - coef }}</div>
    </n-space>

    <br>

    <b>Rezultatai</b>
    <n-data-table :columns="columns" :data="sortItems(modelValues, coef)" />

  </n-space>
</template>

<script>
import { defineComponent, ref } from 'vue'


export default defineComponent({
  setup () {
    function sortItems(value, coef) {
      let copy = JSON.parse(JSON.stringify(value))
      return copy.sort((a, b) => {
        return (
          -a.price * coef + a.quality * (100-coef)) - (-b.price * coef + b.quality * (100-coef)
        )
      }).reverse()
    }
    return {
      columns: [
        {
          title: 'Įmonė',
          key: 'name'
        },
        {
          title: 'Kaina',
          key: 'price'
        },
        {
          title: 'Kokybė',
          key: 'quality'
        }
      ],
      sortItems,
      coef: ref(50),
      modelValues: ref([{name: 'A', price: 10, quality: 10}]),
      onCreate () {
        return {}
      }
    }
  }
})
</script>
